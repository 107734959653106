import io from 'socket.io-client'
import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      socket: io(process.env.VUE_APP_SERVER || 'http://localhost:3000', {
        withCredentials: true
      })
    }
  }
})
