<template>
  <div class="home">
    <input class="game-id" v-on:keyup.enter='navigateToGame(route)' v-model="route" placeholder="game id"/>
    <div/>
    <div class="create-position">
      <router-link :to=route class="create">Go</router-link>
    </div>
    <div v-if="error">Room is full</div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      error: false,
      route: ""
    }
  },
  methods: {
    navigateToGame(route) {
      this.$router.push({ path: route })
    }
  }
}
</script>

<style>

.game-id {
  width:200px;
  padding:20px;
  box-sizing: border-box;
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 150px;
  outline: none;
}

.create-position {
  padding: 25px;
}

.create {
  padding: 10px;
  border: 2px solid #eee;
  text-decoration: none;
  border-radius: 150px;
  background: #b3b3b3ff;
  color: black;
  outline: none;
}

.create:hover {
  background-color: darkgray;
}

</style>
